<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-select v-model="search.coupon_status" clearable placeholder="请选择券状态" size="small">
          <el-option
              v-for="item in couponStatusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" clearable placeholder="请选择使用状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入券码" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="name"
          label="充值用户"
          align="center"
          width="250">
        <template slot-scope="scope">
          <div v-if="scope.row.name" class="member-box">
            <img :src="scope.row.avatar" />
            <div>
              <div>{{scope.row.name}}（{{scope.row.user_id}}）</div>
              <div>{{scope.row.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="source_type"
          label="发放渠道"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="code"
          label="优惠券码"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="优惠内容"
          align="left"
          width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_type==1">减{{ scope.row.coupon_val }}元</span>
          <span v-if="scope.row.coupon_type==2">打{{ scope.row.coupon_val / 10 }}折</span>
          <span v-if="scope.row.coupon_type==3">兑：{{ scope.row.goods_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="start_time"
          label="有效期"
          align="center"
          width="180">
        <template slot-scope="scope">
          {{ scope.row.start_time | truncate(10) }} ~ {{ scope.row.end_time | truncate(10) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="生效状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ scope.row.effect_status }}
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="领取时间"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="status"
          label="核销状态"
          align="center"
          width="110">
        <template slot-scope="scope">
          {{ scope.row.status == 1 ? '已核销' : '未核销' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="username"
          label="核销人"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="use_time"
          label="核销时间"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="130">
        <template slot-scope="scope">
          <el-popconfirm v-if="scope.row.status==0"
              title="确认核销当前券吗？"
              @confirm="hexiaoGroup(scope.row)"
          >
            <el-button slot="reference" type="text" size="small">核销</el-button>
          </el-popconfirm>
          <el-popconfirm class="mg-lf-10" v-if="scope.row.status==0"
              title="确认删除当前券吗？"
              @confirm="delGroup(scope.row)"
          >
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        coupon_status: '',
        coupon_id: '',
        member_id: ''
      },
      tableData: [],
      statusList: [
        {id: 1, title: '已使用'},
        {id: 2, title: '未使用'},
      ],
      couponStatusList: [
        {id: 1, title: '已生效'},
        {id: 2, title: '未生效'},
        {id: 3, title: '已过期'},
      ]
    };
  },
  created() {
    if (this.$route.query.coupon_id) {
      this.search.coupon_id = this.$route.query.coupon_id
    }
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        coupon_status: that.search.coupon_status,
        member_id: that.search.member_id,
        coupon_id: that.search.coupon_id
      }
      this.$api.marking.couponLog(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    hexiaoGroup(item) {
      this.$api.marking.couponHexiao({id: item.id}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup(item) {
      this.$api.marking.couponSubDel({id: item.id}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
